@media  (min-width:900px) {

  .logo {
    padding-left: 8.7vh;
    width: 120px;
    
    
  }
  .H-Logout-button {
    text-align: center;
    align-items: center; 
    justify-content: center;
    color: #ffffff;
    /* border: solid 1px #1F8EFA; */
    border-radius: 12px;
    width: 9vh;
    height: 10vh;
    /* background-color: #20293C */
  }

  .button-language {
    padding-left: 3px;
    padding-right: 3px;
  }
  .language{
    position: fixed;
    top:6%;
    left: 75%;
    color: white ;
    display: flex;

  }


  
  .gif_tall {
    
    width: 140px;
   
  }
  
  /* .gif_position {
    position: fixed;
    top:0;
    left: 87%;
  
  } */
  
  .camera-info{
    
   
    font-size: 20px;
    font-weight: 300;
    color: white;
    display: flex;
    justify-content: cente;
    align-items: center;
    
  }
  }




/* size for tablet */
@media (min-width: 800px) and (max-width:900px) {
  .button-language {
    padding: 1 1 
  }

  .language{
    position: fixed;
    top:7%;
    left: 75%;
    color: white ;
  }

.header {
	width: 100%;
	margin-bottom: 30%;
  height: 110px;
}

.header .logo {
  width: 20%;
  height:40% ;
  
}
.header_logo_position {
  position: fixed;
  top:20px;
  left: 2%;



}
.header .wrapper {
  justify-content: space-between;
  padding: 3% 1%;
  
}

.header .wrapper .server-icon {
    margin-right: 20px;
  }

.header .wrapper .username {
    margin-left: 20px;
    
  }

.gif_tall {
  
  width: 180px;
 
}

.gif_position {
  position: fixed;
  top:0;
  left: 650px;

}

.camera-info{
  position: fixed;
  top: 40px;
  left:170px;
  font-size: 20px;
  font-weight: 300;
  color: white;
  
  
}
}
/* for smartphone */
@media (max-width:767px){
  .logo {
    padding-left: 2vh;
    width: 100px !important;
   
    
    
  }
  .H-Logout-button {
    text-align: center;
    align-items: center; 
    justify-content: center;
    color: #ffffff;
    /* border: solid 1px #1F8EFA; */
    border-radius: 12px;
    width: 9vh;
    height: 13vh;
    background-color: #030a25
  }

  .button-language {
    padding-left: 3px;
    padding-right: 3px;
  }
  .language{
    position: fixed;
    top:6%;
    left: 75%;
    color: white ;
    display: flex;

  }


 
  
  .camera-info{
    
   
    font-size: 2vh;
    font-weight: 300;
    color: white;
    
    
  }
  /* .button-language {
    padding: 1 1 
  }
  .language{
    display: flex;
    font-size: 140%;
    font-weight: 450;
    margin-top: 1%;
  }

  .header {
    width: 100%;
    margin-bottom: 30%;
    height: 13%;
  }
  
  .header .logo {
    width: 20%;
    height:40% ;
    
  }
  .header_logo_position {
    position: fixed;
    top:20px;
    left: 2%;
  
  
  
  }
  .header .wrapper {
    justify-content: space-between;
    padding: 3% 1%;
    
  }
  
  .header .wrapper .server-icon {
      margin-right: 20px;
    }
  
  .header .wrapper .username {
      margin-left: 20px;
      
    }
  
  .gif_tall {
    
    width: 150px;
   
  }
  
  .gif_position {
    position: fixed;
    top:0;
    left: 63%;
  
  }
  
  .camera-info{
    position: fixed;
    top: 30px;
    left: 24%;
    font-size: 80%;
    font-weight: 300;
    color: white;
    
    
  } */



}