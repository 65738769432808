.rounded {
	border-radius: 12px;
	overflow: hidden;
}

.V-content-box-rounded {
	background: #2F3B52;
	background: var(--color-secondary-dark);
	overflow: hidden;
	border-radius: 12px;
	
	padding: 0px;
}

.content-box-rounded .flex {
	justify-content: space-between;
	align-items: center;
}

.content-box-rounded .title {
	padding: 4px 0;
}

.content-box-rounded hr {
	border: none;
	border-bottom: solid 2px var(--color-bg-first);
	margin: 8px 0;
}
