



.navigation .menu {
    list-style: none;
    padding: 0;
    margin: 35px 0 0 0;
}

.navigation .menu li a {
    position: relative;
    color: #ffffff;
    color: #ffffff;
    padding: 20px 40px;
    display: flex;
    background: transparent;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
}

.navigation .menu li a::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 7px;
    height: 100%;
    background: #1F8EFA;
    background: #1F8EFA;
    z-index: 1;
    pointer-events: none;
    transform: translateX(-7px);
    transition: all 0.3s ease;
}

@media (hover: hover) {
    .navigation .menu li a:hover {
        background: #20293C;
        background: #20293C;
        color: #1F8EFA;
        color: #1F8EFA;
    }

    .navigation .menu li a:hover::after {
        transform: translateX(0);
    }
}

.navigation .menu li a.active {
    background: #20293C;
    background: #20293C;
    color: #1F8EFA;
    color: #1F8EFA;
}

.navigation .menu li a.active::after {
    transform: translateX(0);
}


.icon::before {
    background-size: contain !important;
    background-position: center !important;
}

.icon::before {
    content: "";
    display: inline-flex;
    min-width: 24px;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-right: 10px;
}

.icon.alerts::before {
    background: url(./static/alerts.svg) no-repeat;
}