@media (min-width:900px) {
	.AD-div-column {
		width: 25%;
		margin-bottom: 6%;
	}

	.AD-space-between {
		margin-bottom: 4%;
	}

	.AD-space-between-actions {
		margin-bottom: 1.5%;
	}





	.AD-w-phone {

		padding-right: 4%;
	}


	h1,
	h5,
	h6 {

		font-family: "Inter";
		font-weight: 300;
		margin: 0;
		font-size: 180%;

		line-height: 1.20;

		color: #ffffff;
		position: relative;
		z-index: 0;

	}

	h1:before,
	h2:before,
	h3:before,
	h4:before,
	h5:before,
	h6:before {
		content: "";
		display: block;
		height: calc(71px + 10px);

		margin: calc(-1 * 71px - 10px) 0 0;

		pointer-events: none;
	}




	h6 {
		font-size: 12px;
		font-size: var(--size-h6-mobile);
		line-height: 1.40;
		line-height: var(--lineheight-h6-mobile);
	}


	h5 {
		font-size: 14px;

		line-height: 1.40;

	}

	.AD-flex {
		display: flex;
	}

	.AD-div-alertDetails {
		display: flex;
	}

	.AD-gap-40 {
		gap: 40px;
	}

	.AD-w-700 {
		width: 45%;
	}


	.AD-content-box {
		display: block;
		background: #2f3b5200;

		overflow: hidden;
		border-radius: 2px;
		padding: 8px;
	}

	.AD-box-shadow {
		box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3),
			-5px -5px 10px rgba(0, 0, 0, 0.3);
		transition: box-shadow 400ms ease-in-out;
	}

	.AD-gap-20 {
		gap: 20px;
	}

	.AD-justify-between {
		justify-content: space-between;
	}

	.AD-title {
		padding: 30px 0;
	}

	.AD-video {
		display: flex;
		border-radius: 2px;
		overflow: hidden;
		line-height: 1;
		aspect-ratio: 16 / 9;
		position: relative;
	}

	.AD-video * {
		display: flex;
		-o-object-fit: cover;
		object-fit: cover;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}


	.AD-emergency-message {
		padding: 12px;
		background: #FF0000;

		color: #ffffff;

		border-radius: 4px;
	}

	.AD-resolved-message {
		padding: 12px;
		background: rgb(42, 150, 51);

		color: #ffffff;

		border-radius: 4px;
	}

	.AD-mt-16 {
		margin-top: 16px;
	}



	.AD-strong {
		font-weight: bolder;
		font-weight: 700;
		color: #ffffff;

	}

	.AD-space {
		display: block;
	}

	.AD-space.size-24:not(.horizontal) {
		min-height: 24px;
		max-height: 24px;
	}

	.AD-space.horizontal.size-24 {
		min-width: 24px;
		max-width: 24px;
	}

	.AD-space.horizontal.size-500 {
		min-width: 50px;
		max-width: 50px;
	}

	.AD-space.size-8:not(.horizontal) {
		min-height: 8px;
		max-height: 8px;
	}

	.AD-space.horizontal.size-8 {
		min-width: 8px;
		max-width: 8px;
	}

	.AD-justify-around {
		justify-content: space-around;
	}

	.AD-gap-8 {
		gap: 66%;
	}

	.AD-gap-buttons {
		gap: 66%
	}

	.AD-flex-1 {
		flex: 1;
	}
	.AD-unclicked-button{
		opacity: 0.5;
	}

	.AD-clicked-button{
		opacity: 1;
	}
	.AD-button-red {
		padding: 15px 25px;
		--color-primary-light: #fda4af;
		--color-primary: #FF0000;
		--color-primary-dark: #9f1239;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;
		align-items: center;
		

	}
	.AD-button-red:active {
		transform: scale(0.95);
		/* Scaling button to 0.98 to its original size */
		box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
		/* Lowering the shadow */
	}

	a,
	button {
		cursor: pointer;
		outline: none;
		text-decoration: none;
		transition: all 0.3s ease;
	}

	button,
	input[type="submit"],
	.AD-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background: none;
		border: none;
		border-radius: 0;
		cursor: pointer;
		padding: 0;
		margin: 0;
		font-size: 16px;

		line-height: 1.00;

		display: inline-block;
		vertical-align: middle;
		white-space: nowrap;
		-webkit-hyphens: auto;
		hyphens: auto;
	}


	button,
	input[type="submit"],
	.AD-button {

		padding: 3px 25px;

		border-radius: 100px;
		font-weight: 600;
		background: #1F8EFA;
		background: var(--color-primary);
		border: solid 2px #1F8EFA;
		border: solid 2px var(--color-primary);
		color: #ffffff;

		box-shadow: 0 0 0 0 #7EC8FF;
		box-shadow: 0 0 0 0 var(--color-primary-light);
	}

	.AD-button-undo {
		padding: 4% 9%;
		background-color: #6e6e6e;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;

	}

	.AD-button-green {
		padding: 15px 25px;
		--color-primary-light: #83008f;
		--color-primary: #5a0692;
		--color-primary-dark: #68005f;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;
		border-radius: 20px;
		padding-right: 37px;
		padding-left: 37px;
		
	}
	.AD-button-green:active {
		transform: scale(0.95);
		/* Scaling button to 0.98 to its original size */
		box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
		/* Lowering the shadow */
	}

	.AD-button-resolve {
		padding: 15px 25px;
		--color-primary-light: #83008f;
		--color-primary: #5a0692;
		--color-primary-dark: #68005f;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;
		border-radius: 20px;
		padding-right: 37px;
		padding-left: 37px;
		margin-top: 30px;
		margin-bottom: 30px
	}

	.AD-my-auto {
		margin-top: auto;
		margin-bottom: auto;
	}

	.AD-light-text {
		color: rgba(255, 255, 255, 0.5);
	}

	.AD-text-right {
		text-align: right;
	}

	.AD-text-start {
		text-align: start;
	}

	.AD-text-center {
		text-align: center;
	}

	.AD-max-w-420 {
		max-width: 420px;
	}

	.AD-pr-40 {
		padding-right: 40px;
	}

	.AD-map-iframe {
		width: 100%;
		line-height: 1;
		margin-bottom: 8%;
	}

	.AD-map-iframe iframe {
		width: 100%;
		border-radius: 12px;
		overflow: hidden;
		aspect-ratio: 10 / 7;
	}


	.AD-rounded {
		border-radius: 12px;
		overflow: hidden;
	}

	.AD-content-box-rounded {
		background: #2F3B52;
		background: var(--color-secondary-dark);
		overflow: hidden;
		border-radius: 12px;

		padding: 8px;
	}

	.AD-content-box-rounded .flex {
		justify-content: space-between;
		align-items: center;
	}

	.AD-content-box-rounded .title {
		padding: 4px 0;
	}

	.AD-content-box-rounded hr {
		border: none;
		border-bottom: solid 2px var(--color-bg-first);
		margin: 8px 0;
	}

	#myImg_ezechiel {
		border-radius: 5px;
		cursor: pointer;
		transition: 0.3s;
	}

	.AD-snapshot_tall {
		height: 10%;
		width: 100%;
	}


	.AD-fullscreen {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

	}

	.AD-fullscreen img {
		max-width: 100%;
		max-height: 100%;
		animation: zoom-in 0.3s ease-in-out;
	}

	.AD-fullscreen.active {
		opacity: 1;
	}

	@keyframes zoom-in {
		from {
			transform: scale(0.2);

		}

		to {
			transform: scale(1);

		}
	}

	.AD-tall-time-since-alert,
	h6 {
		font-size: 119%;
		width: 100%;
		color: #ffffff;
	}

	.AD-tall-time-since-alert,
	span {
		font-size: 119%;
		width: 100%;
		color: #ffffff;
	}
}

@media (min-width: 800px) and (max-width:900px) {

	.AD-div-column{
		width: 25%;
		margin-bottom: 6%;
	}

	.AD-space-between {
		margin-bottom: 4%;
	}

	.AD-space-between-actions {
		margin-bottom: 1.5%;
	}

	h1,
	h5,
	h6 {

		font-family: "Inter";
		font-weight: 300;
		margin: 0;
		font-size: 300%;

		line-height: 1.20;

		color: #ffffff;
		position: relative;
		z-index: 0;

	}

	h1:before,
	h2:before,
	h3:before,
	h4:before,
	h5:before,
	h6:before {
		content: "";
		display: block;
		height: calc(71px + 10px);

		margin: calc(-1 * 71px - 10px) 0 0;

		pointer-events: none;
	}




	.AD-w-phone {

		padding-right: 4%;
	}


	h1,
	h5,
	h6 {

		font-family: "Inter";
		font-weight: 300;
		margin: 0;
		font-size: 180%;

		line-height: 1.20;

		color: #ffffff;
		position: relative;
		z-index: 0;

	}

	h1:before,
	h2:before,
	h3:before,
	h4:before,
	h5:before,
	h6:before {
		content: "";
		display: block;
		height: calc(71px + 10px);

		margin: calc(-1 * 71px - 10px) 0 0;

		pointer-events: none;
	}




	h6 {
		font-size: 12px;
		font-size: var(--size-h6-mobile);
		line-height: 1.40;
		line-height: var(--lineheight-h6-mobile);
	}


	h5 {
		font-size: 14px;

		line-height: 1.40;

	}

	.AD-flex {
		display: flex;
	}

	.AD-div-alertDetails {
		display: flex;
	}

	.AD-gap-40 {
		gap: 40px;
	}

	.AD-w-700 {
		width: 45%;
	}


	.AD-content-box {
		display: block;
		background: #2f3b5200;

		overflow: hidden;
		border-radius: 2px;
		padding: 8px;
	}

	.AD-box-shadow {
		box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3),
			-5px -5px 10px rgba(0, 0, 0, 0.3);
		transition: box-shadow 400ms ease-in-out;
	}

	.AD-gap-20 {
		gap: 20px;
	}

	.AD-justify-between {
		justify-content: space-between;
	}

	.AD-title {
		padding: 30px 0;
	}

	.AD-video {
		display: flex;
		border-radius: 2px;
		overflow: hidden;
		line-height: 1;
		aspect-ratio: 16 / 9;
		position: relative;
	}

	.AD-video * {
		display: flex;
		-o-object-fit: cover;
		object-fit: cover;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}


	.AD-emergency-message {
		padding: 12px;
		background: #FF0000;

		color: #ffffff;

		border-radius: 4px;
	}

	.AD-resolved-message {
		padding: 12px;
		background: rgb(42, 150, 51);

		color: #ffffff;

		border-radius: 4px;
	}

	.AD-mt-16 {
		margin-top: 16px;
	}



	.AD-strong {
		font-weight: bolder;
		font-weight: 700;
		color: #ffffff;

	}

	.AD-space {
		display: block;
	}

	.AD-space.size-24:not(.horizontal) {
		min-height: 24px;
		max-height: 24px;
	}

	.AD-space.horizontal.size-24 {
		min-width: 24px;
		max-width: 24px;
	}

	.AD-space.horizontal.size-500 {
		min-width: 50px;
		max-width: 50px;
	}

	.AD-space.size-8:not(.horizontal) {
		min-height: 8px;
		max-height: 8px;
	}

	.AD-space.horizontal.size-8 {
		min-width: 8px;
		max-width: 8px;
	}

	.AD-justify-around {
		justify-content: space-around;
	}

	.AD-gap-8 {
		gap: 66%;
	}

	.AD-gap-buttons {
		gap: 66%
	}


	.AD-flex-1 {
		flex: 1;
	}
	.AD-unclicked-button{
		opacity: 0.5;
	}

	.AD-clicked-button{
		opacity: 1;
	}
	.AD-button-red {
		padding: 15px 25px;
		--color-primary-light: #fda4af;
		--color-primary: #FF0000;
		--color-primary-dark: #9f1239;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;
		align-items: center;

	}
	.AD-button-red:active {
		transform: scale(0.95);
		/* Scaling button to 0.98 to its original size */
		box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
		/* Lowering the shadow */
	}


	a,
	button {
		cursor: pointer;
		outline: none;
		text-decoration: none;
		transition: all 0.3s ease;
	}

	button,
	input[type="submit"],
	.AD-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background: none;
		border: none;
		border-radius: 0;
		cursor: pointer;
		padding: 0;
		margin: 0;
		font-size: 16px;

		line-height: 1.00;

		display: inline-block;
		vertical-align: middle;
		white-space: nowrap;
		-webkit-hyphens: auto;
		hyphens: auto;
	}


	button,
	input[type="submit"],
	.AD-button {

		padding: 3px 25px;

		border-radius: 100px;
		font-weight: 600;
		background: #1F8EFA;
		background: var(--color-primary);
		border: solid 2px #1F8EFA;
		border: solid 2px var(--color-primary);
		color: #ffffff;

		box-shadow: 0 0 0 0 #7EC8FF;
		box-shadow: 0 0 0 0 var(--color-primary-light);
	}

	.AD-button-undo {
		padding: 4% 9%;
		background-color: #6e6e6e;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;

	}

	.AD-button-green {
		padding: 15px 25px;
		--color-primary-light: #83008f;
		--color-primary: #5a0692;
		--color-primary-dark: #68005f;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;
		border-radius: 20px;
		padding-right: 37px;
		padding-left: 37px
	}
	.AD-button-green:active {
		transform: scale(0.95);
		/* Scaling button to 0.98 to its original size */
		box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
		/* Lowering the shadow */
	}

	.AD-button-resolve {
		padding: 15px 25px;
		--color-primary-light: #83008f;
		--color-primary: #5a0692;
		--color-primary-dark: #68005f;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;
		border-radius: 20px;
		padding-right: 37px;
		padding-left: 37px;
		margin-top: 30px;
		margin-bottom: 30px
	}

	.AD-my-auto {
		margin-top: auto;
		margin-bottom: auto;
	}

	.AD-light-text {
		color: rgba(255, 255, 255, 0.5);
	}

	.AD-text-right {
		text-align: right;
	}

	.AD-text-start {
		text-align: start;
	}

	.AD-text-center {
		text-align: center;
	}

	.AD-max-w-420 {
		max-width: 420px;
	}

	.AD-pr-40 {
		padding-right: 40px;
	}

	.AD-map-iframe {
		width: 100%;
		line-height: 1;
		margin-bottom: 8%;
	}

	.AD-map-iframe iframe {
		width: 100%;
		border-radius: 12px;
		overflow: hidden;
		aspect-ratio: 10 / 7;
	}


	.AD-rounded {
		border-radius: 12px;
		overflow: hidden;
	}

	.AD-content-box-rounded {
		background: #2F3B52;
		background: var(--color-secondary-dark);
		overflow: hidden;
		border-radius: 12px;

		padding: 8px;
	}

	.AD-content-box-rounded .flex {
		justify-content: space-between;
		align-items: center;
	}

	.AD-content-box-rounded .title {
		padding: 4px 0;
	}

	.AD-content-box-rounded hr {
		border: none;
		border-bottom: solid 2px var(--color-bg-first);
		margin: 8px 0;
	}

	#myImg_ezechiel {
		border-radius: 5px;
		cursor: pointer;
		transition: 0.3s;
	}

	.AD-snapshot_tall {
		height: 10%;
		width: 100%;
	}


	.AD-fullscreen {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

	}

	.AD-fullscreen img {
		max-width: 100%;
		max-height: 100%;
		animation: zoom-in 0.3s ease-in-out;
	}

	.AD-fullscreen.active {
		opacity: 1;
	}

	@keyframes zoom-in {
		from {
			transform: scale(0.2);

		}

		to {
			transform: scale(1);

		}
	}

	.AD-tall-time-since-alert,
	h6 {
		font-size: 119%;
		width: 100%;
		color: #ffffff;
	}

	.AD-tall-time-since-alert,
	span {
		font-size: 119%;
		width: 100%;
		color: #ffffff;
	}
}

@media  (max-width:762px){
	.AD-div-column{
		width: 100%;
		margin-bottom: 9%;
	}

	.AD-space-between {
		margin-bottom: 9%;
	}

	.AD-space-between-actions {
		margin-bottom: 3%;
	}

	.AD-div-alertDetails {
		padding-right: 5%;
	}

	.AD-w-phone {

		padding-right: 4%;
	}


	h1,
	h5,
	h6 {

		font-family: "Inter";
		font-weight: 300;
		margin: 0;
		font-size: 180%;

		line-height: 1.20;

		color: #ffffff;
		position: relative;
		z-index: 0;

	}

	h1:before,
	h2:before,
	h3:before,
	h4:before,
	h5:before,
	h6:before {
		content: "";
		display: block;
		height: calc(71px + 10px);

		margin: calc(-1 * 71px - 10px) 0 0;

		pointer-events: none;
	}




	h6 {
		font-size: 12px;
		font-size: var(--size-h6-mobile);
		line-height: 1.40;
		line-height: var(--lineheight-h6-mobile);
	}


	h5 {
		font-size: 14px;

		line-height: 1.40;

	}

	.AD-flex {
		display: flex;
	}

	.AD-gap-40 {
		gap: 40px;
	}

	.AD-w-700 {
		width: 100%;
	}


	.AD-content-box {
		display: block;
		background: #2f3b5200;

		overflow: hidden;
		border-radius: 2px;
		padding: 8px;
	}

	.AD-box-shadow {
		box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3),
			-5px -5px 10px rgba(0, 0, 0, 0.3);
		transition: box-shadow 400ms ease-in-out;
	}

	.AD-gap-20 {
		gap: 20px;
	}

	.AD-justify-between {
		justify-content: space-between;
	}

	.AD-title {
		padding: 30px 0;
	}

	.AD-video {
		display: flex;
		border-radius: 2px;
		overflow: hidden;
		line-height: 1;
		aspect-ratio: 16 / 9;
		position: relative;
	}

	.AD-video * {
		display: flex;
		-o-object-fit: cover;
		object-fit: cover;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}


	.AD-emergency-message {
		padding: 12px;
		background: #FF0000;

		color: #ffffff;

		border-radius: 4px;
	}

	.AD-resolved-message {
		padding: 12px;
		background: rgb(42, 150, 51);

		color: #ffffff;

		border-radius: 4px;
	}

	.AD-mt-16 {
		margin-top: 16px;
	}



	.AD-strong {
		font-weight: bolder;
		font-weight: 700;
		color: #ffffff;

	}

	.AD-space {
		display: block;
	}

	.AD-space.size-24:not(.horizontal) {
		min-height: 24px;
		max-height: 24px;
	}

	.AD-space.horizontal.size-24 {
		min-width: 24px;
		max-width: 24px;
	}

	.AD-space.horizontal.size-500 {
		min-width: 50px;
		max-width: 50px;
	}

	.AD-space.size-8:not(.horizontal) {
		min-height: 8px;
		max-height: 8px;
	}

	.AD-space.horizontal.size-8 {
		min-width: 8px;
		max-width: 8px;
	}

	.AD-justify-around {
		justify-content: space-around;
	}

	.AD-gap-8 {
		gap: 66%;
	}



	.AD-flex-1 {
		flex: 1;
	}

	.AD-unclicked-button{
		opacity: 0.5;
	}

	.AD-clicked-button{
		opacity: 1;
	}

	.AD-button-red {
		padding: 15px 25px;
		--color-primary-light: #fda4af;
		--color-primary: #FF0000;
		--color-primary-dark: #9f1239;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;
		align-items: center;

	}
	.AD-button-red:active {
		transform: scale(0.95);
		/* Scaling button to 0.98 to its original size */
		box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
		/* Lowering the shadow */
	}


	a,
	button {
		cursor: pointer;
		outline: none;
		text-decoration: none;
		transition: all 0.3s ease;
	}

	button,
	input[type="submit"],
	.AD-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background: none;
		border: none;
		border-radius: 0;
		cursor: pointer;
		padding: 0;
		margin: 0;
		font-size: 16px;

		line-height: 1.00;

		display: inline-block;
		vertical-align: middle;
		white-space: nowrap;
		-webkit-hyphens: auto;
		hyphens: auto;
	}


	button,
	input[type="submit"],
	.AD-button {

		padding: 3px 25px;

		border-radius: 100px;
		font-weight: 600;
		background: #1F8EFA;
		background: var(--color-primary);
		border: solid 2px #1F8EFA;
		border: solid 2px var(--color-primary);
		color: #ffffff;

		box-shadow: 0 0 0 0 #7EC8FF;
		box-shadow: 0 0 0 0 var(--color-primary-light);
	}

	.AD-button-undo {
		padding: 4% 9%;
		background-color: #6e6e6e;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;

	}

	.AD-button-green {
		padding: 15px 25px;
		--color-primary-light: #83008f;
		--color-primary: #5a0692;
		--color-primary-dark: #68005f;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;
		border-radius: 20px;
		padding-right: 37px;
		padding-left: 37px
	}
	.AD-button-green:active {
		transform: scale(0.95);
		/* Scaling button to 0.98 to its original size */
		box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
		/* Lowering the shadow */
	}

	.AD-button-resolve {
		padding: 15px 25px;
		--color-primary-light: #83008f;
		--color-primary: #5a0692;
		--color-primary-dark: #68005f;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;
		border-radius: 20px;
		padding-right: 37px;
		padding-left: 37px;
		margin-top: 30px;
		margin-bottom: 30px
	}

	.AD-my-auto {
		margin-top: auto;
		margin-bottom: auto;
	}

	.AD-light-text {
		color: rgba(255, 255, 255, 0.5);
	}

	.AD-text-right {
		text-align: right;
	}

	.AD-text-start {
		text-align: start;
	}

	.AD-text-center {
		text-align: center;
	}

	.AD-max-w-420 {
		max-width: 420px;
	}

	.AD-pr-40 {
		padding-right: 40px;
	}

	.AD-map-iframe {
		width: 100%;
		line-height: 1;
		margin-bottom: 9%;
	}

	.AD-map-iframe iframe {
		width: 100%;
		border-radius: 12px;
		overflow: hidden;
		aspect-ratio: 10 / 7;
	}


	.AD-rounded {
		border-radius: 12px;
		overflow: hidden;
	}

	.AD-content-box-rounded {
		background: #2F3B52;
		background: var(--color-secondary-dark);
		overflow: hidden;
		border-radius: 12px;

		padding: 8px;
	}

	.AD-content-box-rounded .flex {
		justify-content: space-between;
		align-items: center;
	}

	.AD-content-box-rounded .title {
		padding: 4px 0;
	}

	.AD-content-box-rounded hr {
		border: none;
		border-bottom: solid 2px var(--color-bg-first);
		margin: 8px 0;
	}

	#myImg_ezechiel {
		border-radius: 5px;
		cursor: pointer;
		transition: 0.3s;
	}

	.AD-snapshot_tall {
		height: 10%;
		width: 100%;
	}


	.AD-fullscreen {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

	}

	.AD-fullscreen img {
		max-width: 100%;
		max-height: 100%;
		animation: zoom-in 0.3s ease-in-out;
	}

	.AD-fullscreen.active {
		opacity: 1;
	}

	@keyframes zoom-in {
		from {
			transform: scale(0.2);

		}

		to {
			transform: scale(1);

		}
	}

	.AD-tall-time-since-alert,
	h6 {
		font-size: 119%;
		width: 100%;
		color: #ffffff;
	}

	.AD-tall-time-since-alert,
	span {
		font-size: 119%;
		width: 100%;
		color: #ffffff;
	}

}


@media (max-width:100px) {

	.AD-div-column{
		width: 100%;
		margin-bottom: 9%;
	}

	.AD-space-between {
		margin-bottom: 9%;
	}

	.space-between-actions {
		margin-bottom: 3%;
	}

	.div-alertDetails {
		padding-right: 5%;
	}

	.w-phone {

		padding-right: 4%;
	}


	h1,
	h5,
	h6 {

		font-family: "Inter";
		font-weight: 300;
		margin: 0;
		font-size: 180%;

		line-height: 1.20;

		color: #ffffff;
		position: relative;
		z-index: 0;

	}

	h1:before,
	h2:before,
	h3:before,
	h4:before,
	h5:before,
	h6:before {
		content: "";
		display: block;
		height: calc(71px + 10px);

		margin: calc(-1 * 71px - 10px) 0 0;

		pointer-events: none;
	}




	h6 {
		font-size: 12px;
		font-size: var(--size-h6-mobile);
		line-height: 1.40;
		line-height: var(--lineheight-h6-mobile);
	}


	h5 {
		font-size: 14px;

		line-height: 1.40;

	}

	.flex {
		display: flex;
	}

	.gap-40 {
		gap: 40px;
	}

	.w-700 {
		width: 100%;
	}


	.content-box {
		display: block;
		background: #2f3b5200;

		overflow: hidden;
		border-radius: 2px;
		padding: 8px;
	}

	.box-shadow {
		box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3),
			-5px -5px 10px rgba(0, 0, 0, 0.3);
		transition: box-shadow 400ms ease-in-out;
	}

	.gap-20 {
		gap: 20px;
	}

	.justify-between {
		justify-content: space-between;
	}

	.title {
		padding: 30px 0;
	}

	.video {
		display: flex;
		border-radius: 2px;
		overflow: hidden;
		line-height: 1;
		aspect-ratio: 16 / 9;
		position: relative;
	}

	.video * {
		display: flex;
		-o-object-fit: cover;
		object-fit: cover;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}


	.emergency-message {
		padding: 12px;
		background: #FF0000;

		color: #ffffff;

		border-radius: 4px;
	}

	.resolved-message {
		padding: 12px;
		background: rgb(42, 150, 51);

		color: #ffffff;

		border-radius: 4px;
	}

	.mt-16 {
		margin-top: 16px;
	}



	.strong {
		font-weight: bolder;
		font-weight: 700;
		color: #ffffff;

	}

	.space {
		display: block;
	}

	.space.size-24:not(.horizontal) {
		min-height: 24px;
		max-height: 24px;
	}

	.space.horizontal.size-24 {
		min-width: 24px;
		max-width: 24px;
	}

	.space.horizontal.size-500 {
		min-width: 50px;
		max-width: 50px;
	}

	.space.size-8:not(.horizontal) {
		min-height: 8px;
		max-height: 8px;
	}

	.space.horizontal.size-8 {
		min-width: 8px;
		max-width: 8px;
	}

	.justify-around {
		justify-content: space-around;
	}

	.gap-8 {
		gap: 66%;
	}



	.flex-1 {
		flex: 1;
	}
	.unclicked-button{
		opacity: 0.5;
	}

	.clicked-button{
		opacity: 1;
	}
	.button-red {
		padding: 15px 25px;
		--color-primary-light: #fda4af;
		--color-primary: #FF0000;
		--color-primary-dark: #9f1239;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;
		align-items: center;

	}
	.button-red:active {
		transform: scale(0.95);
		/* Scaling button to 0.98 to its original size */
		box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
		/* Lowering the shadow */
	}


	a,
	button {
		cursor: pointer;
		outline: none;
		text-decoration: none;
		transition: all 0.3s ease;
	}

	button,
	input[type="submit"],
	.button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background: none;
		border: none;
		border-radius: 0;
		cursor: pointer;
		padding: 0;
		margin: 0;
		font-size: 16px;

		line-height: 1.00;

		display: inline-block;
		vertical-align: middle;
		white-space: nowrap;
		-webkit-hyphens: auto;
		hyphens: auto;
	}


	button,
	input[type="submit"],
	.button {

		padding: 3px 25px;

		border-radius: 100px;
		font-weight: 600;
		background: #1F8EFA;
		background: var(--color-primary);
		border: solid 2px #1F8EFA;
		border: solid 2px var(--color-primary);
		color: #ffffff;

		box-shadow: 0 0 0 0 #7EC8FF;
		box-shadow: 0 0 0 0 var(--color-primary-light);
	}

	.button-undo {
		padding: 4% 9%;
		background-color: #6e6e6e;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;

	}

	.button-green {
		padding: 15px 25px;
		--color-primary-light: #83008f;
		--color-primary: #5a0692;
		--color-primary-dark: #68005f;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;
		border-radius: 20px;
		padding-right: 37px;
		padding-left: 37px
	}
	.button-green:active {
		transform: scale(0.95);
		/* Scaling button to 0.98 to its original size */
		box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
		/* Lowering the shadow */
	}

	.button-resolve {
		padding: 15px 25px;
		--color-primary-light: #83008f;
		--color-primary: #5a0692;
		--color-primary-dark: #68005f;
		box-shadow: 5px 5px 10px rgba(6, 6, 7, 0.6),
			-5px -5px 10px rgba(6, 6, 7, 0.6);
		transition: box-shadow 400ms ease-in-out;
		border-radius: 20px;
		padding-right: 37px;
		padding-left: 37px;
		margin-top: 30px;
		margin-bottom: 30px
	}

	.my-auto {
		margin-top: auto;
		margin-bottom: auto;
	}

	.light-text {
		color: rgba(255, 255, 255, 0.5);
	}

	.text-right {
		text-align: right;
	}

	.text-start {
		text-align: start;
	}

	.text-center {
		text-align: center;
	}

	.max-w-420 {
		max-width: 420px;
	}

	.pr-40 {
		padding-right: 40px;
	}

	.map-iframe {
		width: 100%;
		line-height: 1;
		margin-bottom: 9%;
	}

	.map-iframe iframe {
		width: 100%;
		border-radius: 12px;
		overflow: hidden;
		aspect-ratio: 10 / 7;
	}


	.rounded {
		border-radius: 12px;
		overflow: hidden;
	}

	.content-box-rounded {
		background: #2F3B52;
		background: var(--color-secondary-dark);
		overflow: hidden;
		border-radius: 12px;

		padding: 8px;
	}

	.content-box-rounded .flex {
		justify-content: space-between;
		align-items: center;
	}

	.content-box-rounded .title {
		padding: 4px 0;
	}

	.content-box-rounded hr {
		border: none;
		border-bottom: solid 2px var(--color-bg-first);
		margin: 8px 0;
	}

	#myImg_ezechiel {
		border-radius: 5px;
		cursor: pointer;
		transition: 0.3s;
	}

	.snapshot_tall {
		height: 10%;
		width: 100%;
	}


	.fullscreen {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

	}

	.fullscreen img {
		max-width: 100%;
		max-height: 100%;
		animation: zoom-in 0.3s ease-in-out;
	}

	.fullscreen.active {
		opacity: 1;
	}

	@keyframes zoom-in {
		from {
			transform: scale(0.2);

		}

		to {
			transform: scale(1);

		}
	}

	.tall-time-since-alert,
	h6 {
		font-size: 119%;
		width: 100%;
		color: #ffffff;
	}

	.tall-time-since-alert,
	span {
		font-size: 119%;
		width: 100%;
		color: #ffffff;
	}
}