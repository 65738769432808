@media (min-width: 800px) and (max-width:900px) {

	html{
		/* background: #242E42; */
		background: linear-gradient(to right, #275c86, #030a25 75%);
		font-family: "Inter";
		/* transition: background 0.5s ease; */
		color: #ffffff;
		color: var(--color-light);
		margin: 0;
		padding: 1%;

	}

	.main {
		display: flex;
		gap: 20px;
		padding-top: 120px
	}

	.header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;

		padding: 24px 0;
		z-index: 999;
		background: linear-gradient(to right, #275c86, #030a25 75%);
		margin-bottom: 100px;
	}

	.header .logo {
		width: 120px;

	}

	.header .wrapper {
		justify-content: space-between;
		padding: 0 20px;
	}

	.header .wrapper .server-icon {
		margin-right: 20px;
	}

	.header .wrapper .username {
		margin-left: 20px;

	}

	.content {
		flex: 1;
		margin-bottom: 10%;
	}


}


@media (min-width: 900px) {

	html{
		/* background: #242E42; */
		background: linear-gradient(to right, #275c86, #030a25 75%);
		font-family: "Inter";
		/* transition: background 0.5s ease; */
		color: #ffffff;
		color: var(--color-light);
		margin:0;
	padding: 1%;
	}

	.main {
		display: flex;
		gap: 20px;
		padding-top: 150px
	}

	.header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;

		padding: 24px 0;
		z-index: 999;
		background: linear-gradient(to right, #275c86, #030a25 75%);
		margin-bottom: 100px;
	}

	.header .logo {
		width: 120px;

	}

	.header .wrapper {
		justify-content: space-between;
		padding: 0 20px;
	}

	.header .wrapper .server-icon {
		margin-right: 20px;
	}

	.header .wrapper .username {
		margin-left: 20px;

	}

	.content {
		flex: 1;
		margin-bottom: 10%;
	}


}




@media (max-width:767px) {

	html{
		/* background: #242E42; */
		background: linear-gradient(to right, #275c86, #030a25 75%);
		font-family: "Inter";
		/* transition: background 0.5s ease; */
		color: #ffffff;
		color: var(--color-light);
		margin:0;
	padding: 5%;
	width:100% !important;

	}

	.main {
		display: flex;
		gap: 20px;
		padding-top: 120px;
		width: 100%;
	}

	.header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 13%;
		padding: 24px 0;
		z-index: 999;
		background: linear-gradient(to right, #275c86, #030a25 75%);
		margin-bottom: 100px;
	}

	.header .logo {
		width: 120px;

	}

	.header .wrapper {
		justify-content: space-between;
		padding: 0 20px;
	}

	.header .wrapper .server-icon {
		margin-right: 20px;
	}

	.header .wrapper .username {
		margin-left: 20px;

	}

	.content {

		margin-bottom: 10%;
	}


}